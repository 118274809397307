import * as React from "react";

//css
import "../components/index.css";

//photos
import profilePic from "../images/pp.png";

//files
import resume from "../files/Beaumont-Phua-Resume.pdf";

//FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/font-awesome";
import {
  faCss3Alt,
  faHtml5,
  faJava,
  faJs,
  faReact,
} from "@fortawesome/free-brands-svg-icons";

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import "@fortawesome/fontawesome-svg-core/styles.css";
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false; /* eslint-disable import/first */

import Navbar from "../components/layout";
//NPM Versioning
import { version } from "../../package.json";

const IndexPage = () => {
  return (
    <main className="flex">
      <title>Home Page</title>
      <div className="mx-auto">
        <Navbar></Navbar>
        <h1 className="heading text-3xl font-bold mt-4 mb-2">
          Hi, I'm Beaumont Phua
        </h1>
        <h1 className="heading  text-2xl font-bold mb-8">
          Junior full stack developer based in Singapore
        </h1>
        <div className="grid grid-cols-2 mb-8">
          <div className="w-1/2">
            <img
              alt="Profile"
              src={profilePic}
              className="rounded-full mx-auto"
              width="225"
              height="225"
            />
          </div>
          <div className="flex flex-col">
            <p className="text-2xl">Contact</p>
            <div>
              <a
                className="hover:bg-blue-100 inline"
                target="_blank"
                href="https://www.linkedin.com/in/beaumont-phua-975332b1/"
              >
                linkedin
              </a>
              <span>&nbsp;|&nbsp;</span>
              <a
                className="hover:bg-blue-100  inline"
                href={resume}
                target="_blank"
              >
                Resume
              </a>
            </div>
            <a
              className="hover:bg-blue-100"
              target="_blank"
              href="https://github.com/mpsslh"
            >
              Github
            </a>
            <a
              className="hover:bg-blue-100"
              href="mailto:beaumontphua@gmail.com"
            >
              Email
            </a>
          </div>
        </div>

        <p className="w-1/2 text-2xl mb-2">Skills</p>
        <p className="w-1/2 text-1xl mb-2">Front-End</p>

        <div className="grid grid-cols-4">
          <div className="flex flex-col mb-4">
            <p>
              <FontAwesomeIcon icon={faHtml5} color="#e34f26" />
            </p>
            <p className>HTML</p>
          </div>

          <div className="flex flex-col mb-4">
            <p>
              <FontAwesomeIcon icon={faCss3Alt} color="#1680C0" />
            </p>
            <p className>CSS</p>
          </div>

          <div className="flex flex-col mb-4">
            <p>
              <FontAwesomeIcon icon={faJs} color="#4679BD" />
            </p>
            <p className>JS</p>
          </div>

          <div className="flex flex-col mb-4">
            <p>
              <FontAwesomeIcon icon={faReact} color="#61DBFB" />
            </p>
            <p className>React</p>
          </div>
        </div>
        <p className="w-1/2 text-1xl mb-2">Back-End</p>
        <div className="grid grid-cols-4">
          <div className="flex flex-col mb-4">
            <p>
              <FontAwesomeIcon icon={faJava} color="#f89820" />
            </p>
            <p className>JAVA</p>
          </div>
          {/* <p>Projects</p> */}
        </div>
        <footer className="mt-8">
          <p>Website built with Gatsby and TailwindCss.</p>
          <p>Version {version} Beaumont Phua 2021</p>
        </footer>
      </div>
    </main>
  );
};

export default IndexPage;
